import { css } from '@linaria/core';
import React, { useCallback } from 'react';
import { ContentCard } from '../../components/styles';
import { IconButton } from '../../composing/CSButton';
import { FlexRow } from '../../composing/Flex';
import { SpacerH, Spacing } from '../../composing/Spacing';
import { PieChart } from '../../theme/NewIcons';
import { useUser } from '../auth/AuthContext';
import { useEphemeralModal } from '../modal/Modal';
import { ProfileAvatar } from '../../components/Avatar';
import PseudoInputButton from 'src/components/PseudoInputButton';
import { ZIndexLayers } from 'src/theme/constants';
import EditMemo from '../memo/EditMemo';
import { PortfolioShareModal } from '../share/ShareModal';
import UploadFileButton from 'src/components/UploadFileButton';
import { FeedItem, TradeFeedItem } from '@commonstock/common/src/api/feed';
import { LinkTradeButton, LinkTradeButtonVariant } from '../link-content/LinkPreviousTrade';
type Props = {
  prependItem?: (feedItem: FeedItem) => void;
}; // eslint-disable-next-line @typescript-eslint/no-unused-vars

export const FeedCreatePostContent = ({
  prependItem
}: Props) => {
  const [profile] = useUser();
  const modal = useEphemeralModal();
  const prompt = `What's on your mind?`;
  const openPortfolioModal = useCallback(() => {
    const onImageSelect = (image: string) => {
      modal(null);
      modal(<EditMemo isModal files={[image]} />);
    };

    profile && modal(<PortfolioShareModal onImageSelect={onImageSelect} uuid={profile.user_uuid} user={{ ...profile,
      uuid: profile.user_uuid
    }} />);
  }, [modal, profile]);
  const onInputClick = useCallback(() => {
    modal(<EditMemo isModal />);
  }, [modal]);
  const onFileUpload = useCallback((file: File) => {
    modal(<EditMemo isModal files={[file]} />);
  }, [modal]);
  const onLinkTrades = useCallback((trades: TradeFeedItem[]) => {
    modal(<EditMemo isModal tradeFeedItems={trades} />);
  }, [modal]);
  if (!profile) return null;
  return <Spacing padding={[0, 0, 1, 0]}>
      <FlexRow alignCenter>
        <ProfileAvatar username={profile.username} name={profile.name} avatar={profile.picture_raw} />
        <SpacerH rem={0.75} />
        <PseudoInputButton onClick={onInputClick} prompt={prompt} />
        <SpacerH rem={0.75} />
        <IconButton onClick={openPortfolioModal} aria-label={'create a post with portfolio'} className={buttonClass}>
          <PieChart size={1.25} />
        </IconButton>
        <SpacerH rem={0.75} />
        <UploadFileButton onFileUpload={onFileUpload} />
        <SpacerH rem={0.75} />
        <LinkTradeButton buttonVariant={LinkTradeButtonVariant.ICON} onClick={onLinkTrades} trades={[]} />
      </FlexRow>
    </Spacing>;
};

function FeedCreatePost(props: Props) {
  return <Spacing padding={[1, 1, 0.75, 1]} margin={[0, 0, 1]}>
      <ContentCard className={slightlyUpZindex}>
        <FeedCreatePostContent {...props} />
      </ContentCard>
    </Spacing>;
}

export default FeedCreatePost;
const slightlyUpZindex = "s1v6qcot";
const buttonClass = "b5i4tcn";

require("../../../.linaria-cache/packages/oxcart/src/scopes/feed/FeedCreatePost.linaria.module.css");